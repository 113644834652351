import React from 'react'
import amb_icon1 from "../icons/amb_icon-01.png";
import amb_icon2 from "../icons/amb_icon-02.png";
import amb_icon3 from "../icons/amb_icon-03.png";
import amb_icon4 from "../icons/amb_icon-04.png";
import amb_icon5 from "../icons/amb_icon-05.png";
import amb_icon6 from "../icons/amb_icon-06.png";
import amb_icon7 from "../icons/amb_icon-07.png";
import amb_icon8 from "../icons/amb_icon-08.png";
import amb_icon9 from "../icons/amb_icon-09.png";
import amb_icon10 from "../icons/amb_icon-10.png";
import amb_icon11 from "../icons/amb_icon-11.png";
import amb_icon12 from "../icons/amb_icon-12.png";
import amb_icon13 from "../icons/amb_icon-13.png";
import amb_icon14 from "../icons/amb_icon-14.png";
import amb_icon15 from "../icons/amb_icon-15.png";
import amb_icon16 from "../icons/amb_icon-16.png";
import amb_icon17 from "../icons/amb_icon-17.png";
import amb_icon18 from "../icons/amb_icon-18.png";
import amb_icon_offline from "../icons/amb_icon-offline.png";

export default function map_icon(status, degree){

	let a = degree + 10
	let b = a/20

	//console.log(b)
	let rounded_degree = parseInt(b) * 20


	//console.log(rounded_degree)
	
	let icon 

	if(status == "\u79bb\u7ebf"){
		icon = amb_icon_offline
	}
	else{

		switch(rounded_degree){
			case 0 : 
				icon = amb_icon1
				break;
			case 20 :
				icon = amb_icon2
				break;
			case 40 : 
				icon = amb_icon3
				break;
			case 60:
				icon = amb_icon4
				break;
			case 80:
				icon = amb_icon5
				break;
			case 100:
				icon = amb_icon6
				break;
			case 120:
				icon = amb_icon7
				break;
			case 140:
				icon = amb_icon8
				break;
			case 160:
				icon = amb_icon9
				break;
			case 180:
				icon = amb_icon10
				break;
			case 200:
				icon = amb_icon11
				break;
			case 220:
				icon = amb_icon12
				break;
			case 240:
				icon = amb_icon13
				break;
			case 260:
				icon = amb_icon14
				break;
			case 280:
				icon = amb_icon15
				break;
			case 300:
				icon = amb_icon16
				break;
			case 320:
				icon = amb_icon17
				break;
			case 340:
				icon = amb_icon18
				break;
		}
	}

	return icon
}