import React, { useContext, useEffect, useState } from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, Polyline } from '@react-google-maps/api';
import { VehicleHistoryContext } from '../Context/VehicleHistoryContext';
import map_icon from '../icons/map_icon';
import { Typography,Box,Tooltip } from '@mui/material';
import { format } from 'date-fns';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAltOutlined';
import SpeedIcon from '@mui/icons-material/SpeedOutlined';
import map_icon_start from '../icons/map_icon_start.png'
import map_icon_finish from '../icons/map_icon_finish.png'

const containerStyle = {
	width: 'calc(100vw - 375px)',
	height: 'calc(100vh - 64px)'
  };


export default function HistoryMapView() {

    const vhContext = useContext(VehicleHistoryContext)
    const [infoOpen,setInfoOpen] = useState(false)
    const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDBqqlC5CP-JGDMqXIKKyw74V693d737pk"
    })



    const onLoad = React.useCallback(function callback(map){

		vhContext.setMap(map)
	  },[])

	  const onUnmount = React.useCallback(function callback(map) {
      vhContext.setMap(null)
	  }, [])


    useEffect(() => {
      
    })

    const drawVehicleInfoWindow = () => {

      if(!vhContext.isDataLoaded){
        return
      }

      if(!infoOpen){
        return
      }

      let cc = vhContext.currentCoor
      let gpsTime = format(new Date(cc.gpsTime * 1000),'HH:mm:ss')
      let gpsDate = format(new Date(cc.gpsTime * 1000),'dd MMM yyyy')
    

      return (
        <InfoWindow
            position={{lat : cc.lat, lng : cc.lng}}
            options={{
              pixelOffset : new window.google.maps.Size(0,-25)
            }}
            onCloseClick={() => {setInfoOpen(false)}}
          >

            <Box>
              {/*<Typography variant="subtitle1" className="if-title">{vhContext.vehicle.name}</Typography>
              <Typography variant="body2">{vhContext.vehicle.description}</Typography>*/}
              <Box marginTop={2} display ="flex">
                <Box>
                  <Box mb={1} mr={3}>
											<Box display ="flex" alignItems={"center"} justifyContent="flex-start"><SatelliteAltIcon className="if-subtitle-icon"/><Typography variant="body2" className="if-subtitle">Time :</Typography></Box>
											<Typography variant="body2" className="if-body">{gpsTime}</Typography>
											<Typography variant="body2" className="if-body">{gpsDate}</Typography>
										</Box>
                </Box>
                <Box mb={1}>
                  <Box display ="flex" alignItems={"center"} justifyContent="flex-start"><SpeedIcon className="if-subtitle-icon"/><Typography variant="body2" className="if-subtitle">Speed :</Typography></Box>
                  <Typography variant="body2" className="if-body">{cc.speed} km/h</Typography>
                </Box>
              </Box>
            </Box>

        </InfoWindow>
      )
    }



    const drawVehicleMarker = () => {

      if(!vhContext.isDataLoaded){
        return
      }
      let cc = vhContext.currentCoor

      let course = ""
      if(typeof(course) != "undefined"){
        course = cc.course
      }
      

      let string_icon = String(map_icon("online",course))

      return (
        
        <Marker
          position={{lat : cc.lat, lng : cc.lng}}

          icon={{
            url : string_icon,
            scaledSize : new window.google.maps.Size(50,50),
            origin : new window.google.maps.Point(0,0),
            anchor : new window.google.maps.Point(25,25)
          }}
          onClick={()=>{setInfoOpen(!infoOpen)}}
        >
        </Marker>
        
      )
      
    }

    const drawStartMarker = () => {
      if(!vhContext.isDataLoaded){
        return
      }

      if (vhContext.startCoor && typeof vhContext.startCoor !== "undefined"){
        return(
          <Marker
            position={{lat : vhContext.startCoor.lat, lng : vhContext.startCoor.lng}}
            icon={{
              url : String(map_icon_start),
              scaledSize : new window.google.maps.Size(50,50)
            }}
          >

          </Marker>
        )
      }
    }
    const drawEndCoor = () => {
      if(!vhContext.isDataLoaded){
        return
      }
      if (vhContext.endCoor && typeof vhContext.endCoor !== "undefined"){
        return(
          <Marker
            position={{lat : vhContext.endCoor.lat, lng : vhContext.endCoor.lng}}
            icon={{
              url : String(map_icon_finish),
              scaledSize : new window.google.maps.Size(50,50)
            }}
          >

          </Marker>
        )
      }
      }



    const drawPath = () =>{
      if(!vhContext.isDataLoaded){
        return
      }
      return(
        <Polyline
        
          path={vhContext.coorPoints}
          geodesic= {true}
          strokeColor="#0000FF"
          strokeOpacity="1.0"
          strokeWeight={20}
          options={{
            strokeColor : "#673ab7",
            strokeOpacity : "1.0",
            strokeWeight : 3
          }}
          center
          
        >

        </Polyline>
      )
    }

      return (
        isLoaded ? (
            <GoogleMap
                mapContainerStyle={containerStyle}
                center={vhContext.mapCenter}
                zoom={14}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
              {drawStartMarker()}
              {drawEndCoor()}
              {drawPath()}
              {drawVehicleMarker()}
              {drawVehicleInfoWindow()}
            </GoogleMap>
        )
        :
			<div>loading</div>

      )

}