import React, { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import GlobalContext from "./GlobalContext";
import { parse,getUnixTime} from 'date-fns'
import { useInterval } from "../Utils/useInterval";

export const VehicleHistoryContext = createContext()

export function VehicleHistoryContextProvider({children}){

	const globalContext = useContext(GlobalContext)
	const [vehicle,setVehicle] = useState({})
	const [map,setMap] = React.useState(null)
	const [mapCenter,setMapCenter] = useState({lat : 0,lng : 0})
	const [coorPoints, setCoorPoints] = useState([])
	const [startCoor,setStartCoor] = useState()
	const [endCoor,setEndCoor] = useState()
	const [isDataLoaded, setIsDataLoaded] = useState(false)
	const [isPlaying, setIsPlaying] = useState(false)
	const [playSpeed, setPlaySpeed] = useState(1)
	const [coorIndex, setCoorIndex] = useState(0)
	const [currentCoor, setCurrentCoor] = useState({})


	useEffect(() => {


	},[])

	const onSliderChanged = (i) => {
		let index = i
		setCoorIndex(index)
		setCurrentCoor(coorPoints[index])
	}

	const loadVehicle = (id) => {
		axios.get(globalContext.apiUrl + "/vehicle/" + id)
		.then((response) =>{
            setVehicle(response.data)
		})
		.catch((err) =>{
			console.log(err)
		})
	}

	const loadCoorPoints = (id,start_time,end_time) =>{

		const unix_start_time = getUnixTime(parse(start_time,"yyyy-MM-dd\'T\'HH:mm",  new Date()))
		const unix_end_time = getUnixTime(parse(end_time,"yyyy-MM-dd\'T\'HH:mm",  new Date()))

		axios.get(globalContext.apiUrl + "/vehicle_history" 
                +"?id=" + id
                +"&start_time=" + unix_start_time
				+"&end_time=" + unix_end_time

        )

        .then((response) => {
            console.log(response.data)

			if(response.data.code == 21008){
				alert("Tanggal tidak bisa melebihi 30 hari. Mohon coba lagi.")
				return
			}
			let cp = response.data.data
			
			if(cp.length < 1){
				alert("Tidak ada data di tanggal ini.")
				setIsDataLoaded(false)
				
				return
			}

			

			const bounds = new window.google.maps.LatLngBounds()

			for(let i =0; i < cp.length;i++){
				cp[i].lat = parseFloat(cp[i].lat)
				cp[i].lng = parseFloat(cp[i].lng)
				var latlang = new window.google.maps.LatLng(cp[i].lat, cp[i].lng)

				bounds.extend(latlang)
			}

			console.log(cp)
			setCoorPoints(cp)

			setStartCoor(cp[0])
			setEndCoor(cp[cp.length-1])

			setMapCenter(bounds.getCenter())
			map.fitBounds(bounds)
			setCurrentCoor(cp[0])
			setIsDataLoaded(true)

			
		})
        .catch((error) => {
            console.log(error)
        })
	}

	useInterval(() => {

		if(isPlaying){
			let current = coorPoints[coorIndex]
			//console.log(current)

			let nextIndex = coorIndex + 1
			if(nextIndex > coorPoints.length -1){
				nextIndex = 0

				setIsPlaying(false)


			}
			setCoorIndex(nextIndex)
			//console.log(coorIndex)
			setCurrentCoor(current)
		}
		
	}, 1000 / playSpeed)

	return (
		<VehicleHistoryContext.Provider value = {
			{
				vehicle,setVehicle,
				loadVehicle,
				map,setMap,
				mapCenter,setMapCenter,
				coorPoints,setCoorPoints,
				startCoor,setStartCoor,
				endCoor,setEndCoor,
				loadCoorPoints,
				isPlaying, setIsPlaying,
				currentCoor, setCurrentCoor,
				coorIndex,setCoorIndex,
				onSliderChanged,
				playSpeed,setPlaySpeed,
				isDataLoaded,setIsDataLoaded
			}
		}
		>
			{children}
		</VehicleHistoryContext.Provider>
	)
}

