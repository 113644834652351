import React from "react";
import {Button, Box} from "@mui/material";
import axios from "axios";
import User from "../models/User";
import {useNavigate} from 'react-router-dom'
import LogoutIcon from '@mui/icons-material/Logout'

import GlobalContext from "../Context/GlobalContext";

function LogoutButton() {

    const context = React.useContext(GlobalContext)

	const navigate = useNavigate()

    const handleLogout = (e) => {
        e.preventDefault()
        //log out..

		axios.post(context.apiUrl + "/logout",
		/*{
			headers : {
				"Content-Type" : "application/json"
			}
		},*/
		{ withCredentials: true })
			.then(() => {

			})
			.catch(() => {
                //handle if something went wrong
            })
            .then(() => {
                //this code will be definitely executed
                User.logout(afterUserDestroyed)
            })

    }

	const afterUserDestroyed = () => {
        
        window.location.href = "/login"
    }


    return (
        <Button onClick={handleLogout} aria-label='logout' variant="contained" color="error" endIcon={<LogoutIcon />}>
            Logout
        </Button>

    )
}

export default LogoutButton