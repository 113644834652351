import React, {createContext,useContext,useEffect,useState} from "react"
import axios from "axios"
import { useInterval } from "../Utils/useInterval"
import GlobalContext from "./GlobalContext";
import User from "../models/User";
import { useNavigate } from "react-router-dom";
import mqtt from "mqtt/dist/mqtt"
import moment from "moment";

export const DashboardContext = createContext()


export function DashboardContextProvider({children}){

	const context = useContext(GlobalContext)
	const navigate = useNavigate()


	const [vehicles,setVehicles] = useState([{name : "",lat : -6.200000,lng : 106.816666,infoOpen : false}])
	const [userList,setUserList] = useState([])

	const [mqttClient, setMqttClient] = useState(null)
	const [payload, setPayload] = useState({"no" : "-1", name : "", lat : 0, lng : 0, infoOpen : false});

	const [vehicleLoaded,setVehicleLoaded] = useState(false)
	const [ indexSelected,setIndexSelected] = useState(0)
	const [user,setUser] = useState({})
	
	const [allCount,setAllCount] = useState(0)
	const [onlineCount,setOnlineCount] = useState(0)
	const [offlineCount,setOfflineCount] = useState(0)

	const [isMapLoaded,setIsMapLoaded] = useState(false)
	const [map,setMap] = React.useState(null)

	const [mapCenter,setMapCenter] = useState({lat : -6.200000,lng : 106.816666})
	const [mapSize,setMapSize] = useState({width: 'calc(100vw - 385px)',height: 'calc(100vh - 64px)'})
	
	const mqttOptions = {
		clientId: 'mqttjs_' + Math.random().toString(16).substr(2, 8),
		username: process.env.REACT_APP_MQTT_USER,
		password: process.env.REACT_APP_MQTT_PASSWORD,
		protocol : 'wss'
  	}
  	const mqttConnect = () => {
		console.log("Connecting...")
		setMqttClient(mqtt.connect(process.env.REACT_APP_MQTT_ADDRESS,mqttOptions))
	}


	const afterUserDestroyed = () => {
        window.location.href = "/login"
		alert("Please Login again")
    }

	useEffect(() => {
		axios.get(context.apiUrl + "/vehicles")
		.then((res) => {
			let v = res.data
			
			let all = 0
			let online = 0
			let offline = 0

			for(let i = 0; i < v.length;i++){
				v[i].index = i;
				v[i].infoOpen = false
				all++
				
				if(v[i].status === "\u79bb\u7ebf"){
					offline++
				}else{
					online++
				}
			}

			setVehicles(v)
		})

	},[])

	const openUserInfoWindow = (index) => {
		//console.log(index)
		let ul = userList;

		ul[index].infoOpen  = !ul[index].infoOpen;

		//console.log(ul[index].infoOpen)
		setUserList([...ul])
	}

	const closeUserInfoWindow = (index) => {
		//console.log(index)
		let i = 0;
		let ul = userList;

		for(i; i < userList.length; i++){
			ul[i].infoOpen = false;
		}
		setUserList(ul)
	}
	// MQTT
	useEffect(() => {
		
		if(mqttClient != null){
			mqttClient.on("connect", () => {
				console.log("MQTT Connected")
			})
			

			mqttClient.on('error', (err) => {
				console.error('Connection error: ', err);
				mqttClient.end();
			});
			mqttClient.on('reconnect', () => {
			});
			mqttClient.on('message', (topic, message) => {
				//const payload = { topic, message: message.toString() };
				try{
					const payload = JSON.parse(message.toString())
					
					setPayload(payload);

				}
				catch(err){
					console.log(err)
				}
			});

			mqttClient.subscribe('/user_position',1,(err) => {
				if(err){
					console.log(err)
					return
				}
			})
		}
	},[mqttClient])


	//ONN MQTT PAYLOAD 
	useEffect(() => {

	
		if(payload.no === "-1"){
			return
		}

		let messageObject = payload

		let ul = userList

		const index = ul.findIndex(element => {
			if (element.no === messageObject.no){
				return true
			}

			return false
		})

		messageObject.last_message = moment()
		//console.log(index)
		if(index !== -1){


			messageObject.infoOpen = ul[index].infoOpen
			ul[index] = messageObject;
			setUserList([...ul])
		}else{
			messageObject.infoOpen = false
			ul.push(messageObject)
			setUserList([...ul])
		}

	},[payload])

	const checkOfflineUsers = () => {

		let now = moment()

		let newUserList = userList

		newUserList.forEach(function(user,i){

			let last_message = moment(user.last_message)
			let diff = now.diff(last_message, "seconds");



			if(diff > 120){
				newUserList.splice(i,1)
			}
		})

		setUserList([...newUserList])

		//console.log(newUserList)
	}

	useInterval(checkOfflineUsers, 10000)



	useEffect(() => {
		axios.interceptors.request.use (
            config => {
                config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
                return config
            },
            error => {
                return Promise.reject(error);
            }
        )

		if(isMapLoaded){
			axios.get(context.apiUrl + "/me",)
			.then((res) => {
				//console.log(res)
				let u = res.data
				setUser(u)

				console.log(u)
				if(u.command_center == "1"){
					setMapSize({width: 'calc(100vw - 610px)',height: 'calc(100vh - 64px)'})
					mqttConnect()
				}
			}).catch((err) => {

				if(err.response.status == 401){
					User.logout(afterUserDestroyed)
				}
				console.log(err.response.status)
			})

			firstLoad()
			
		}
	},[isMapLoaded])

	useInterval(() => {

		if(vehicleLoaded){
			updateVehicles()
		}
	}, 5000);


	const firstLoad = () => {
        axios.get(context.apiUrl + "/vehicles")
        .then((res) => {
            let v = res.data
            
			let all = 0
			let online = 0
			let offline = 0

			const bounds = new window.google.maps.LatLngBounds()

            for(let i = 0; i < v.length;i++){
                v[i].index = i;
                v[i].infoOpen = false
				all++
				
				if(v[i].status === "\u79bb\u7ebf"){
					offline++
				}else{
					online++
				}

				var latlang = new window.google.maps.LatLng(v[i].lat,v[i].lng)
				bounds.extend(latlang)
            }

			setMapCenter(bounds.getCenter())
			map.fitBounds(bounds)
            setVehicles(v)
			setAllCount(all)
			setOnlineCount(online)
			setOfflineCount(offline)
            setVehicleLoaded(true)

        })
    }

    const updateVehicles = () =>{
        axios.get(context.apiUrl + "/vehicles")
        .then((res) => {
            let currentVehicles = vehicles
            let newVehicles = res.data

            let i = 0;

			let all = 0
			let online = 0
			let offline = 0
            
			const bounds = new window.google.maps.LatLngBounds()

            for(i; i < newVehicles.length;i++){
                //console.log(currentVehicles[i].infoOpen)
                newVehicles[i].index = i;
                newVehicles[i].infoOpen =currentVehicles[i].infoOpen
				all++;
				if(newVehicles[i].status === "\u79bb\u7ebf"){
					offline++
				}else{
					online++
				}
            }
            setVehicles(newVehicles)
			setAllCount(all)
			setOnlineCount(online)
			setOfflineCount(offline)
        })

    }

	const toggleVehicleInfoWindow = (index)  => {
		let v = vehicles
        v[index].infoOpen = !v[index].infoOpen
        setVehicles([...v])
	}

	const onVehicleSelected = (index) =>{
		let pos = {lat : parseFloat(vehicles[index].lat), lng : parseFloat(vehicles[index].lng)}
		setMapCenter(pos)

		let v = vehicles

		for(let i = 0; i < v.length; i++){
			v[i].infoOpen = false;
		}

		v[index].infoOpen = true
		setIndexSelected(index)
		setVehicles(v)

	}

	return (
		<DashboardContext.Provider value = {
			{
				isMapLoaded,setIsMapLoaded,
				map,setMap,
				user,setUser,
				vehicles,setVehicles,
				userList,setUserList,
				openUserInfoWindow,closeUserInfoWindow,
				indexSelected,setIndexSelected,
				allCount,setAllCount,
				onlineCount,setOnlineCount,
				offlineCount,setOfflineCount,
				vehicleLoaded,
				onVehicleSelected,
				toggleVehicleInfoWindow,
				mapCenter,setMapCenter,
				mapSize,setMapSize,
			}
		}
		>
			{children}
		</DashboardContext.Provider>
	)
}