import React,{useEffect,useState,useContext} from "react";
import { DashboardContext } from "../Context/DashboardContext";
import { Toolbar,Drawer,TextField,InputAdornment,Box,Typography } from "@mui/material";
import { BorderRight, Search } from '@mui/icons-material';
import SearchBar from "./SearchBar";

export default function SidebarUserList(){

	const {user,userList,setUserList,openUserInfoWindow,closeUserInfoWindow,mapCenter, setMapCenter} = useContext(DashboardContext)
	const [search,setSearch] = React.useState("")


	useEffect(() => {
	})

	const onSearchChanged = (e) => {
		let new_value = e.target.value
		setSearch(new_value)
	}

	const onUserSelect = (index) =>{
		console.log(userList[index])
		let pos = {lat : userList[index].lat, lng : userList[index].lng}
        setMapCenter(pos)
        closeUserInfoWindow(index)
        openUserInfoWindow(index)
	}

	const showAllUsers = () => {

        if(userList.length < 1){
            return
        }
        //let filteredList = Object.values(userList).filter(user => user.no.toLowerCase().includes(search.toLowerCase()   ))
		let filteredList = Object.values(userList).filter(function(user){
			if(user.no.toLowerCase().includes(search.toLowerCase()) || user.name.toLowerCase().includes(search.toLowerCase())){
				return true
			}
			return false
		})
        
        return filteredList.map(function(user,i){

            return (
				<Box key={i} className={`User-row-container`} onClick = {() => {onUserSelect(i)}} >
					<Box style={{flexGrow : 1}}>
						<Box >
							<Typography sx={{color : "rgb(1, 51, 113)", fontWeight : "bold"}} variant="subtitle1">{user.no}</Typography>
							<Typography variant="subtitle2" >{user.name}</Typography>
						</Box>

					</Box>
				</Box>
			)
        })
    
    }

	return (
		user.command_center == "1" ?
		<Drawer 
			variant="permanent"
			anchor="right"
			PaperProps={{
				sx : {
					padding : "1rem",
					backgroundColor: "#e7eef7",
					boxShadow : "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"
				}
			}}
			sx={{
				width: 235,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: { width: 235, boxSizing: 'border-box' },
			}}
		>
			<Toolbar />
			<Typography align="center" variant="h6" style={{color:"#013371",fontWeight : "Bold"}}>User List</Typography>
			<TextField 
				sx={{backgroundColor : "#fff",narginBottom : "1rem"}}
				fullWidth
				margin='dense'
				size="small"
				value={search}
				onChange={(e) => {onSearchChanged(e)}}
				placeholder="Cari"
				InputProps={{
					endAdornment: (
					<InputAdornment position='end'>
						<Search/>
					</InputAdornment>
					),
				}}
			/>
			<Box sx={{marginTop : 2}}>
			{showAllUsers()}
			</Box>
			

		</Drawer>
		:
		null
		
	)

}