import React,{useEffect,useState,useContext} from "react";
import {Box,Button,IconButton} from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import { VehicleHistoryContext } from "../Context/VehicleHistoryContext";
import Slider from '@mui/material/Slider';
export default function HistoryPlayControls() {

	const vhContext = useContext(VehicleHistoryContext)
	const [psText,setPsText] = useState("1X")


	const onPlayButtonClicked = () => {

		let isPlaying = vhContext.isPlaying

		if(!isPlaying){
			vhContext.setIsPlaying(true)
		}else{
			vhContext.setIsPlaying(false)
		}
	}

	const onSliderChanged = (e) =>{

		vhContext.onSliderChanged(e.target.value)

	}

	const onButtonPlaySpeedClicked = (e) => {
		let ps = vhContext.playSpeed
		ps++

		if(ps > 5){
			ps =  1
		}

		vhContext.setPlaySpeed(ps)

		setPsText(ps + "X")
	}



	return (
		vhContext.isDataLoaded && (
		<Box style={{backgroundColor : "#f5f5f5", display:"flex", justifyContent : "center", alignItems : "center", width : 400, position : "absolute", bottom : 15, zIndex : 50000, left: "calc(375px + ((100vw -  375px - 200px) / 2))", padding : "0.5rem",borderRadius : 15, boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
			<IconButton size="small" onClick={() => {onPlayButtonClicked()}}>
				{ !vhContext.isPlaying ?
					(
						<PlayCircleIcon className="button-play-history" />
					)
					: <PauseCircleIcon className="button-pause-history" />
			}
			</IconButton>
			<Button onClick={(e) => {onButtonPlaySpeedClicked()} } size="large" sx={{minWidth : 0}} style={{color : "#673ab7"}}>
				{psText}
			</Button>
			<Slider
				style={{color : "#673ab7"}}
				sx={{margin : "0.5rem", marginRight : "1rem"}}
				aria-label="Steps"
				value={vhContext.coorIndex}
				valueLabelDisplay="auto"
				step={1}
				min={0}
				max={vhContext.coorPoints.length-1}
				onChange={(e)=> {onSliderChanged(e)}}
			>

			</Slider>

		</Box>
		)
	
	)
}