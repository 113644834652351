import React, { useContext, useEffect, useState } from "react";
import user from "../models/User";
import {Box,Toolbar} from "@mui/material";
import Header from "../ComponentParts/Header";
import Sidebar from "../ComponentParts/Sidebar";
import MapView from "../ComponentParts/MapView";

import {DashboardContextProvider,DashboardContext} from "../Context/DashboardContext";
import { Route, Routes } from "react-router-dom";
import EditVehiclePage from "./EditVehiclePage";
import HistoryPage from "./HistoryPage";
import SidebarUserList from "../ComponentParts/SidebarUserList";

function Dashboard() {

    return (

        <DashboardContextProvider>
            <Box style={{display : "flex", flexDirection : "column"}}>
                <Header/>
                <Box style={{display : "flex"}}> 
                    <Sidebar/>
                    <Box>
                        <Toolbar />
                        <Routes>
                            <Route path="/" element={<MapView/>}/>
                            <Route path="/edit_vehicle/:id" element={<EditVehiclePage/>}/>
                            <Route path="/history_vehicle/:id" element={<HistoryPage/>}/>

                        </Routes>
                    </Box>
                    <SidebarUserList/>

                </Box>
                
            </Box>
        </DashboardContextProvider>
    )
}

export default Dashboard