import { Box, Button, Typography,IconButton, Tooltip } from "@mui/material";
import React, { useEffect,useState } from "react";
import HistoryIcon from '@mui/icons-material/History';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from "react-router-dom";
import SensorsIcon from '@mui/icons-material/Sensors';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import { PlayArrow } from "@mui/icons-material";
import axios from "axios";

export default function SidebarVehicleRow({vehicle,indexSelected}){

	const navigate = useNavigate()

	const [vSelected,setVSelected] = useState("")
	const [carPower,setCarPower] = useState("")
	const [carPowerColor,setCarPowerColor] = useState("rgba(0, 0, 0, 0.35)")

	const [signal,setSignal] = useState("")
	const [signalColor,setSignalColor] = useState("rgba(0, 0, 0, 0.35)")

	const onHistoryButtonClicked = () => {
		navigate("/dashboard/history_vehicle/" + vehicle.id)
	}

	const onPlayButtonClicked = () => {


		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ nama: vehicle.name,url : 'rtsp://31.220.6.103:8554/live/0001' })
		};
		
		fetch("http://localhost:5000/live_view",requestOptions)
	}
	
	const onEditButtonClicked = () =>{
		navigate("/dashboard/edit_vehicle/" + vehicle.id)
	}



	useEffect(() =>{

		let vs =""
		if(vehicle.index == indexSelected){
			//console.log("Selected")
			vs = "selected"
		}

		setVSelected(vs)

		let cp = vehicle.accStatus ? 'ON' : "OFF"
		let cpc = vehicle.accStatus ? "#64dd17" : "rgba(0, 0, 0, 0.35)"

		setCarPower(cp)
		setCarPowerColor(cpc)


		let s = ""
		let sColor = ""

		if(vehicle.status == "离线"){
			s = "Offline"
			sColor = "rgba(0, 0, 0, 0.35)"
		}
		else{
			s =  "Online"
			sColor = "#673ab7"
		}

		setSignal(s)

		setSignalColor(sColor)

	},[vehicle,indexSelected])
	

	return (
		<Box className={`Vehicle-row-container ${vSelected}`} >
			<Box style={{flexGrow : 1}}>
				<Box >
					<Typography sx={{color : "rgb(1, 51, 113)", fontWeight : "bold"}} variant="subtitle1">{vehicle.name}</Typography>
					<Typography variant="subtitle2" >{vehicle.description}</Typography>
				</Box>
				<Box style={{display : "flex",  flexDirection : "row"}}>
					<Tooltip title={signal}><SensorsIcon sx={{fontSize : 22, marginTop : "5px",marginRight : "5px", color : signalColor}} /></Tooltip>
					{vehicle.is_wanway == true && (<Tooltip title={`Mesin ${carPower}`}><PowerSettingsNewIcon  sx={{fontSize : 22, marginTop : "5px",color : carPowerColor}} /></Tooltip>)}
				</Box>
			</Box>
			<Box style={{display : "flex", alignItems : "center", justifyContent : "center"}}>
				{vehicle.is_wanway == true && (
					<Tooltip title="History"><IconButton aria-label="History" onClick={() => {onHistoryButtonClicked()}} ><HistoryIcon /></IconButton></Tooltip>
				)}
				<Tooltip title="Edit"><IconButton aria-label="Edit" onClick={() => {onEditButtonClicked()}}><EditIcon /></IconButton></Tooltip>
				<Tooltip title="Play"><IconButton aria-label="Play" onClick={() => {onPlayButtonClicked()}}><PlayArrow /></IconButton></Tooltip>
			</Box>

			
		</Box>
	)
}