import React,{useContext} from "react";
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';
import { DashboardContext } from "../Context/DashboardContext";
import map_icon from "../icons/map_icon";
import { Typography } from "@mui/material";
import Box from '@mui/material/Box';
import SatelliteAltIcon from '@mui/icons-material/SatelliteAltOutlined';
import SpeedIcon from '@mui/icons-material/SpeedOutlined';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import SensorsIcon from '@mui/icons-material/Sensors';

import { format } from "date-fns";
import { color } from "@mui/system";
import moment from "moment";
import userloc from "../Images/userloc.png";
  

export default function MapView(){


	const [containerStyle, setContainerStyle] = React.useState({width: 'calc(100vw - 610px)',height: 'calc(100vh - 64px)'})



	const { isLoaded } = useJsApiLoader({
		id: 'google-map-script',
		googleMapsApiKey: "AIzaSyDBqqlC5CP-JGDMqXIKKyw74V693d737pk"
	  })

	  const dashboardContext = useContext(DashboardContext)
	  


	  const onLoad = React.useCallback(function callback(map){

		dashboardContext.setMap(map)
		dashboardContext.setIsMapLoaded(true)
		

		

	  },[])

	  const onUnmount = React.useCallback(function callback(map) {
		dashboardContext.setMap(null)
	  }, [])



	  const renderAllVehicleInfoWindow = () => {

		if (dashboardContext.vehicleLoaded == false){
			return
		}
		return (
			dashboardContext.vehicles.map(function(vehicle,i){
				if(vehicle.infoOpen ==true){

					const is_wanway = vehicle.is_wanway

					let carPower = ""
					let carPowerColor = "#000000"
					let gpsTime = ""
					let speed = "abc"
					let status = ""
					let statusColor = ""
					let circleColor = ""

					const statusCh = vehicle.status;

						if (statusCh == "离线"){
							status ="Offline"
							statusColor = "#d50000"
							circleColor = "#d50000"
							
						}
						else if (statusCh == "静止"){
							status="Static"
							statusColor = "#140078"
							circleColor = "#64dd17"
						}

					
					if(is_wanway){
						gpsTime = format(new Date(vehicle.signalTime * 1000), 'yyyy/MM/dd | HH:mm')
						carPower = vehicle.accStatus ? 'ON' : "OFF"
						carPowerColor = vehicle.accStatus ? '#64dd17' : "#000000"
						speed = vehicle.speed
						
					}

					else{
						gpsTime = format(new Date(vehicle.updated_at), 'yyyy/MM/dd | HH:mm')
						carPower = "Unknown"
						speed = "Unknown"
					}

					return (
						<InfoWindow
							key={i}
							position={{lat : parseFloat(vehicle.lat), lng : parseFloat(vehicle.lng)}}
							onCloseClick={() => {dashboardContext.toggleVehicleInfoWindow(i)}}
						>
							<Box padding={1}>
								<Typography variant="subtitle1" className="if-title">{vehicle.name}</Typography>
								<Typography variant="body2">{vehicle.description}</Typography>
								<Box marginTop={2} display ="flex">
									<Box>
										<Box mb={1}>
											<Box display ="flex" alignItems={"center"} justifyContent="flex-start"><SatelliteAltIcon className="if-subtitle-icon"/><Typography variant="body2" className="if-subtitle">GPS Time :</Typography></Box>
											<Typography variant="body2" className="if-body">{gpsTime}</Typography>
										</Box>

										{is_wanway == 1 &&(
										<Box mb={1}>
											<Box display ="flex" alignItems={"center"} justifyContent="flex-start"><PowerSettingsNewIcon className="if-subtitle-icon"/><Typography variant="body2" className="if-subtitle">Car Power :</Typography></Box>
											<Typography variant="body2" className="if-body" style={{color : carPowerColor}}>{carPower}</Typography>
										</Box>
										)}
										
									</Box>
									
									<Box marginLeft={5} mr={3}>
										

										<Box mb={1}>
											<Box display ="flex" alignItems={"center"} justifyContent="flex-start"><SensorsIcon className="if-subtitle-icon"/><Typography variant="body2" className="if-subtitle">Status:</Typography></Box>
											<Box display={"flex"} alignItems={"center"} justifyContent="flex-start" className="if-body"><CircleIcon sx={{fontSize : "0.9rem",pr : "2px",color : circleColor}}/><Typography variant="body2" style={{color : statusColor}}>{status}</Typography></Box>
										</Box>
										{is_wanway == 1&& (
										<Box mb={1}>
											<Box display ="flex" alignItems={"center"} justifyContent="flex-start"><SpeedIcon className="if-subtitle-icon"/><Typography variant="body2" className="if-subtitle">Speed :</Typography></Box>
											<Typography variant="body2" className="if-body">{speed} km/h</Typography>
										</Box>
										)}
									</Box>
									
								</Box>
								
								
							</Box>
						</InfoWindow>
					)
				}
			})
		)
	  }





	  const renderAllVehiclePosition = () => {
		if (dashboardContext.vehicleLoaded == false){
			return
		}
		return (
			dashboardContext.vehicles.map(function(vehicle,i){

				let string_icon = String(map_icon("online",0))

				if(vehicle.is_wanway == 1){
					string_icon = String(map_icon(vehicle.status, vehicle.course))
				}

				

				return (
					<Marker
						key={i}
						position={{lat : parseFloat(vehicle.lat), lng : parseFloat(vehicle.lng)}}
						draggable={false}
						icon={{
							url : string_icon,
							scaledSize : new window.google.maps.Size(50,50),
							origin : new window.google.maps.Point(0,0),
							anchor : new window.google.maps.Point(25,0),
							
						}}
						onClick={() => {dashboardContext.toggleVehicleInfoWindow(i)}}
					></Marker>
				)
			})
		)
	  }

	  const loopAllUserPosition = () => {

		return (
			dashboardContext.userList.map(function(user,i){

				//console.log(user.name)
				//console.log(user.lat + " " + user.lng)
				return (
					<Marker
						key={i}
						position={{lat : user.lat, lng : user.lng}}
						draggable = {false}
						icon={{ 
							url : String(userloc),
							scaledSize : new window.google.maps.Size(40,50),
							origin : new window.google.maps.Point(0,0),
							anchor: new window.google.maps.Point(20, 40)
						}}
						onClick={()=> {dashboardContext.openUserInfoWindow(i)}}

					></Marker>
				)
			})
		)
	}

	  const renderUserInfoWindow = () => {
		return (
			dashboardContext.userList.map(function(user,i){
				if(user.infoOpen === true){
					return (
						<InfoWindow
							options={{
								pixelOffset: new window.google.maps.Size(0,-40)
							}}
							key={i}
							position={{lat : user.lat, lng : user.lng}}
							onCloseClick={() => {dashboardContext.openUserInfoWindow(i)}}
						>
							<Box>
								<Typography variant="subtitle1" className="if-title">{user.no}</Typography>
								<Box display={"flex"} flexDirection={"row"} mt={0.5}>
									<Box sx={{width : 60}}>
										<Typography variant="body2" style={{fontWeight : "bold", marginBottom : "0.25rem"}}>Nama</Typography>
									</Box>
									<Box sx={{width : 10}}>
										<Typography variant="body2" style={{fontWeight : "bold", marginBottom : "0.25rem"}}>:</Typography>
									</Box>
									<Box >
										<Typography variant="body2" style={{marginBottom : "0.25rem"}}>{user.name}</Typography>
									</Box>
								</Box>
								<Box display={"flex"} flexDirection={"row"}  mt={0.1}>
									<Box sx={{width : 60}}>
										<Typography variant="body2" style={{fontWeight : "bold", marginBottom : "0.25rem"}}>Waktu</Typography>
									</Box>
									<Box sx={{width : 10}}>
										<Typography variant="body2" style={{fontWeight : "bold", marginBottom : "0.25rem"}}>:</Typography>
									</Box>
									<Box >
										<Typography variant="body2" style={{marginBottom : "0.25rem"}}>{moment(user.last_message).format("HH:mm:ss")}</Typography>
									</Box>
								</Box>
							</Box>
						</InfoWindow>
					)
				}
			})
		)
	}





	  
	return (
		
			isLoaded ? (
				<GoogleMap
					mapContainerStyle={dashboardContext.mapSize}
					center={dashboardContext.mapCenter}
					zoom={14}
					onLoad={onLoad}
					onUnmount={onUnmount}
				>
					{ renderAllVehicleInfoWindow()}
					{ renderAllVehiclePosition() }
					{loopAllUserPosition()}
					{renderUserInfoWindow()}
					<></>
				</GoogleMap>
			) 
			:
			<div>loading</div>


		
	)
}