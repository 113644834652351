import { Navigate } from "react-router-dom";
import user from "../models/User"

const Protected = ({ isLoggedIn, children }) => {

	
 	if (!user.loggedIn) {
 		return <Navigate to="/login" replace />;
 	}
 	return children;

};


export default Protected;