import React,{useContext,createContext, useEffect, useState} from 'react';

import {Switch, Routes,Route, BrowserRouter} from "react-router-dom";
import Login from "./Components/Login"
import Dashboard from './Components/Dashboard';
import Protected from './ComponentRouters/Protected';
import Public from './ComponentRouters/Public';
import axios from 'axios';
import GlobalContext from './Context/GlobalContext';
import User from './models/User';
const apiUrl = process.env.REACT_APP_API_ADDRESS


function App() {

    const [loaded,setLoaded] = useState(false)

    useEffect(() => {
      console.log("LOADIN DATA")
        
      if(User.isLoggedIn){
        axios.interceptors.request.use (
          config => {
              config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
              
              return config
          },
          error => {
              return Promise.reject(error);
          }
        )
      }
      setLoaded(true)
      
    },[])
    return (
      loaded &&(
      <GlobalContext.Provider value={{apiUrl : apiUrl}} >

      
      <BrowserRouter>
      <Routes>

          <Route path={'/'}
          element={<Public><Dashboard/></Public>}
          />

          <Route path={'/login'} 
          element={<Public><Login /></Public>}
          />


          <Route path={'/dashboard/*'}
            element={<Protected><Dashboard /></Protected>}
          />

          
        </Routes>
      </BrowserRouter>
      </GlobalContext.Provider>
      )
    );
}

export default App;
