import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { DashboardContext } from '../Context/DashboardContext';
import SidebarVehicleRow from './SidebarVehicleRow';
import { BorderRight, Search } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';


const drawerWidth = 375;



function TabPanel(props) {
	const { children, value, index, ...other } = props;
  
	return (
	  <div
		role="tabpanel"
		hidden={value !== index}
		id={`simple-tabpanel-${index}`}
		aria-labelledby={`simple-tab-${index}`}
		{...other}
	  >
		{value === index && (
		  <Box sx={{ p: "0.45rem" }}>
			{children}
		  </Box>
		)}
	  </div>
	);
  }

  TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
	return {
	  id: `simple-tab-${index}`,
	  'aria-controls': `simple-tabpanel-${index}`,
	};
  }


export default function Sidebar(){

	const [tabValue,setTabValue] = React.useState(0);
	const [search,setSearch] = React.useState("")
	const [filteredList,setFilteredList] = React.useState([])

	const dashboardContext = React.useContext(DashboardContext)

 
	React.useEffect(() => {
		let filteredList = Object.values(dashboardContext.vehicles).filter(lv => lv.name.toLowerCase().includes(search.toLowerCase()))
		setFilteredList(filteredList)
	},[dashboardContext.vehicles])

	React.useEffect(() => {

		let filteredList = Object.values(dashboardContext.vehicles).filter(lv => lv.name.toLowerCase().includes(search.toLowerCase()))
		setFilteredList(filteredList)
	},[search])

	const onSearchChanged = (e) =>{
		let new_value = e.target.value
		setSearch(new_value)
	}

	const handleTabChange = (event,newValue) => {
		setTabValue(newValue)
	}


	return(

		
		<Drawer 
			variant="permanent"
			PaperProps={{
				sx : {
					backgroundColor: "#e7eef7",
					boxShadow : "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px"
				}
			}}
			sx={{
				width: drawerWidth,
				flexShrink: 0,
				[`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
			}}
		>
			<Toolbar />
			<Box sx={{ display : "flex", flexDirection : "column", overflow: 'auto', p : 1,pt : 2 }}>
				<Typography align="center" variant="h5" style={{color:"#013371",fontWeight : "Bold"}}>Car List</Typography>
				<TextField 
					sx={{backgroundColor : "#fff"}}
					fullWidth
					margin='dense'
					size="small"
					value={search}
					onChange={(e) => {onSearchChanged(e)}}
					placeholder="Cari"
					InputProps={{
						endAdornment: (
						<InputAdornment position='end'>
							<Search/>
						</InputAdornment>
						),
					}}
				/>
				<Box sx={{ overflow: 'auto', borderBottom: 1, borderColor: 'divider', mt : 1 }}>
					<Tabs value={tabValue} onChange={handleTabChange} aria-label="Vehicle Tabs" variant="fullWidth">
						<Tab label={"Semua (" + dashboardContext.allCount + ")"} style={{fontSize : 12}} {...a11yProps(0)} />
						<Tab label={"Online (" + dashboardContext.onlineCount + ")"} style={{fontSize : 12}} {...a11yProps(1)} />
						<Tab label={"Offline (" + dashboardContext.offlineCount + ")"} style={{fontSize : 12}} {...a11yProps(2)} />
					</Tabs>
				</Box>
				<Box style={{overflow : "auto"}}>
					{/*PANEL ALL */}
					<TabPanel value={tabValue} index={0}>
						<List>
							
							{filteredList.map((vehicle, index) => (
							<ListItem disableGutters key={index} onClick={() => dashboardContext.onVehicleSelected(vehicle.index)}>

								<SidebarVehicleRow vehicle={vehicle} indexSelected={dashboardContext.indexSelected} />
							</ListItem>
							))}
						</List>
					</TabPanel>

					{/*PANEL ONLINE */}
					<TabPanel value={tabValue} index={1}>

						<List>
							{(filteredList.filter(v => v.status != "\u79bb\u7ebf")).map((vehicle, index) => (
							<ListItem disableGutters key={index} onClick={() => dashboardContext.onVehicleSelected(vehicle.index)}>
								<SidebarVehicleRow vehicle={vehicle} indexSelected={dashboardContext.indexSelected}/>
							</ListItem>
							))}
						</List>

					{/*PANEL OFFLINE */}
					</TabPanel>
					<TabPanel value={tabValue} index={2}>
						{(filteredList.filter(v => v.status == "\u79bb\u7ebf")).map((vehicle, index) => (
							<ListItem disableGutters key={index} onClick={() => dashboardContext.onVehicleSelected(vehicle.index)}>
								<SidebarVehicleRow vehicle={vehicle} indexSelected={dashboardContext.indexSelected}/>
							</ListItem>
						))}
					</TabPanel>
				</Box>
			</Box>
		</Drawer>
	)
}