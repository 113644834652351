import { Navigate } from "react-router-dom";
import user from "../models/User"

const Public = ({ isLoggedIn, children }) => {

	console.log(user)
 	if (user.loggedIn != null) {
 		return <Navigate to="/dashboard" replace />;
 	}
 	return children;

};


export default Public;