import { Box,IconButton,Typography,Divider,Button } from '@mui/material'
import React, { useContext,useEffect,useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate,useParams } from 'react-router-dom';
import GlobalContext from '../Context/GlobalContext';
import { VehicleHistoryContext, VehicleHistoryContextProvider } from '../Context/VehicleHistoryContext';

import HistoryMapView from '../ComponentParts/HistoryMapView';
import HistoryDateSelector from '../ComponentParts/HistoryDateSelector';
import HistoryPlayControls from '../ComponentParts/HistoryPlayControls';

const containerStyle = {
	width: 'calc(100vw - 375px)',
	height: 'calc(100vh - 64px)'
  };

export default function HistoryPage(){

    const globalContext = useContext(GlobalContext)
    const vhContext = useContext(VehicleHistoryContext)
    const navigate = useNavigate()

    let {id} = useParams()

    useEffect(() => {
        
    },[])

    


    return (
        <VehicleHistoryContextProvider>
            <Box style={{backgroundColor : "#f5f5f5", display : "flex", flexDirection : "column",width : containerStyle.width, height : containerStyle.height, boxShadow: "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px"}}>
                
                
                <HistoryDateSelector id={id}/>
                <HistoryPlayControls/>
                <HistoryMapView />
            </Box>
        </VehicleHistoryContextProvider>
    )
}