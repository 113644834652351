import { Box, Button, Divider, IconButton, Typography } from '@mui/material'
import React, {useEffect, useState,useContext} from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {TextField} from '@mui/material';
import { useParams, useNavigate,useLocation} from 'react-router-dom'
import GlobalContext from '../Context/GlobalContext';
import axios from 'axios'
import { set } from 'date-fns';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const containerStyle = {
	width: 'calc(100vw - 375px)',
	height: 'calc(100vh - 64px)'
  };


export default function  EditVehiclePage(){

	const globalContext = useContext(GlobalContext)
	const navigate = useNavigate()
	const location = useLocation()
	
	let {id} = useParams()
	const [loaded,setLoaded] = useState(false)
	const [name,setName] = useState("")
	const [description,setDescription] = useState("")
	const [snackOpen,setSnackOpen] = useState(false)


	const handleSnackClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
	
		setSnackOpen(false);
	  };


	useEffect(() =>{
		axios.get(globalContext.apiUrl + "/vehicle/" + id)
		.then((response) =>{

			setName(response.data.name)
			setDescription(response.data.description)

		})
		.catch((err) =>{
			console.log(err)
		})
		.then(() =>{
			setLoaded(true)
		})
	},[location])


	const submit = () => {
		axios.put(globalContext.apiUrl + "/vehicle/" + id, 
			{
				name : name,
				description : description
			}
		)
		.then((response) => {
			console.log(response)
			setSnackOpen(true)
		})
		.catch((error) => {
			console.log(error)
		})
	}
	return(
				
			<Box style={{backgroundColor : "#f5f5f5", display : "flex", flexDirection : "column",width : containerStyle.width, height : containerStyle.height}}>
			<Box style={{display : "flex", flexDirection : "column", padding : "2rem"}} >
				<Box><IconButton onClick={() => {navigate("/dashboard")}}><ArrowBackIcon /><Typography variant="h6">Back</Typography></IconButton></Box>
				{loaded && (
				<Box style={{padding : "2rem", display : "flex", flexDirection : "column", justifyContent : "center"}}>
					<Typography variant="h5" style={{color : "#013371"}}>Edit Vehicle</Typography>
					<Divider sx={{marginBottom : "0.5rem"}} />


					<TextField
						value={name}
						onChange={(e) => {setName(e.target.value)}}
						margin="normal"
						label="Nama"
						autoFocus
						variant="standard"
						sx={{
							width : 300
						}}
					/>

					<TextField
						value={description}
						onChange={(e) => {setDescription(e.target.value)}}
						margin="normal"
						label="Deskripsi"
						autoFocus
						variant="standard"
						sx={{
							width : 300
						}}
					/>

					<Button onClick={() => {submit()}} variant='contained' size='large' sx={{width : 300,marginTop : "2rem"}}>Simpan</Button>
					<Divider sx={{marginTop : "3rem"}} />
				</Box>
				)}
				
			</Box>
			<Snackbar open={snackOpen} autoHideDuration={2500} onClose={handleSnackClose} anchorOrigin={{vertical: 'top',horizontal: 'center'}}>
				<Alert onClose={handleSnackClose} severity="success" sx={{ width: '100%' }}>
					Berhasil Disimpan
				</Alert>
			</Snackbar>
		</Box>
		
	)
}