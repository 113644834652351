import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import logo_livetracking from '../icons/logo_akma_livetracking.png'
import LogoutButton from '../Components/LogoutButton'

import {DashboardContext} from '../Context/DashboardContext'
import { useNavigate } from 'react-router-dom';




function Header() {

	const navigate = useNavigate()
	const dbContext = React.useContext(DashboardContext)

	return(
		<Box sx={{ flexGrow: 1 }}>
			<AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor : "#3490dc" }}>
				<Toolbar>
					<Box
						component="img"
						src={logo_livetracking}
						height={35}
						style={{cursor : "pointer"}}
						onClick= {() => {navigate("/dashboard")}}
					>
					</Box>
					<Typography  variant="h5" sx={{ flexGrow: 1,display : "flex",alignItems : "center", justifyContent : "center" }}>{dbContext.user.name}</Typography>
					<LogoutButton></LogoutButton>
				</Toolbar>
			</AppBar>
    	</Box>
	)
}

export default Header