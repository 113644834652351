import React, { useContext,useState } from "react";
import {
    Box, 
    Button,
    Container,
    TextField,
    CssBaseline,
    Typography,
    Snackbar,
    
} from "@mui/material";

import axios from "axios";
import user from "../models/User";
import { useNavigate, useParams } from "react-router-dom";

import GlobalContext from "../Context/GlobalContext";
import LoginBanner from "../Images/login_banner.png"
import logo_livetracking from "../icons/logo_akma_livetracking.png"
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


  
function Login() {

    const navigate = useNavigate();
    const context = useContext(GlobalContext)

    const [showPassword,setShowPassword] = useState(false)
    const [openNotification,setOpenNotification] = useState(false)

    const handleNotificationClose = (event,reason) =>{
		if(reason === 'clickaway'){
			return
		}

		setOpenNotification(false);
	}

    const toggleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleSubmit = (event) => {
        //TODO handle form submit
        event.preventDefault()
        const formData = new FormData(event.currentTarget);

        const loginCredentials = {
            email : formData.get('email'),
            password : formData.get('password')
        }

        function authenticatedCallback() {
            axios.interceptors.request.use (
                config => {
                    config.headers['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
                    return config
                },
                error => {
                    return Promise.reject(error);
                }
            )
            navigate("/dashboard")
        }

        axios.post(context.apiUrl + '/login', loginCredentials)
            .then((response) =>{
                console.log(response)
                user.authenticated(response.data,authenticatedCallback)
            })
            .catch((err) => {
                setOpenNotification(true)
            })

            


    }

    return (
        <Box style={{position : "relative",marginBottom : 25}}>

            <Box   
                flexGrow={1}
                height={500}
                width="100%"
                style={{backgroundImage : `url(${LoginBanner})`, backgroundPosition : "center center"}}
            />
            <Box style={{display : "flex", alignItems : "center", justifyContent : "center", position : "absolute", top : 300, width : "100%",paddingBottom : 50 }}>
                <Box style={{display : "flex", flexDirection : "column", alignItems : "center", borderRadius : 20,marginBottom : 25, border : "1px solid #013371", backgroundColor : "#fff",height : 400, width : 442, boxShadow :  "rgb(19 65 151 / 68%) 0px 2px 21px 0px" }}>
                    
                    
                    <Box
                        component="img"
                        src={logo_livetracking}
                        width="300px"
                        marginTop={"1rem"}
                        marginBottom={"2rem"}
                    />

                    <Typography variant="h4" style={{color : "#575757"}}>LOGIN</Typography>
                    <Box component={"form"} onSubmit={handleSubmit} style={{padding : "2rem", paddingTop : 0, marginTop : -5}}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Username"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            variant="standard"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            autoComplete="current-password"
                            variant="standard"
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton
                                      aria-label='toggle password visibility'
                                      onClick={toggleShowPassword}
                                      onMouseDown={(event) => {event.preventDefault()}}>
                                      {!showPassword && <Visibility />}
                                      {showPassword && <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                        />
                        <Button
                            size="large"
                            fullWidth
                            variant={"contained"}
                            type={"submit"}
                            sx={{ mt: 4, mb: 2,borderRadius : 20 }}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>
                
            </Box>

            <Snackbar open={openNotification} autoHideDuration={6000} onClose={handleNotificationClose} anchorOrigin={{horizontal : "center",vertical : "bottom"}}>
				<Alert onClose={handleNotificationClose} severity="error" sx={{ width: 500 }}>
					Salah username dan/atau password.
				</Alert>
			</Snackbar>             
        </Box>
    )
}

export default Login