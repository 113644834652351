import React,{useContext,useState,useEffect} from "react";
import { format,subDays } from "date-fns";
import { Box,Typography,Button,IconButton } from "@mui/material";
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate,useParams } from 'react-router-dom';
import { VehicleHistoryContext } from "../Context/VehicleHistoryContext";

export  default function HistoryDateSelector({id}){

	const vhContext = useContext(VehicleHistoryContext)
	const navigate = useNavigate()
	const [startTime, setStartTime] =useState(format(subDays(new Date(),7),"yyyy-MM-dd\'T\'HH:mm"))
    const [endTime, setEndTime] =useState(format(new Date(),"yyyy-MM-dd\'T\'HH:mm"))

	useEffect(() => {
		vhContext.loadVehicle(id)
	},[])

	const onGoClicked = () => {
        vhContext.loadCoorPoints(id,startTime,endTime)
    }
	return(
		<Box>
			<Box style={{display : "flex", flexDirection : "row", alignItems: "center",justifyContent : "space-between", padding : "0.5rem", paddingBottom : 0}} >
                    <Box><IconButton onClick={() => {navigate("/dashboard")}}><ArrowBackIcon /><Typography variant="h6">Back</Typography></IconButton></Box>
                    <Box><Typography variant="h5" style={{color : "#013371"}}>History : {vhContext.vehicle.name}</Typography></Box>
                    <Box sx={{width : "80px"}}></Box>
			</Box>
			<Box display={"flex"} mb="1rem" justifyContent={"center"} alignItems="center">
				
				<Typography variant='body1'>Dari :</Typography>
				<TextField onChange={(e) => {setStartTime(e.target.value)}} type={"datetime-local"} sx={{ml : "0.25rem", mr : "1rem"}}  defaultValue={startTime} size="small"/>
				<Typography variant='body1'>Sampai :</Typography>
				<TextField onChange={(e) => {setEndTime(e.target.value)}} type={"datetime-local"}  sx={{ml : "0.25rem", mr : "1rem"}} defaultValue={endTime}  size="small"/>
				<Button variant="contained" onClick={() => {onGoClicked()}}>GO</Button>
			</Box>
		</Box>
	)

}